import { Component, OnInit } from '@angular/core';
import { AccountService } from './account.service';
import { Account } from './account.model';

@Component({
  selector: 'app-account-management',
  templateUrl: './account-management.component.html',
  styleUrls: ['./account-management.component.css']
})
export class AccountManagementComponent implements OnInit {

  selectedAccount!: Account;

  constructor(private accountService: AccountService) { }

  ngOnInit(): void {
      this.accountService.accountSelected.subscribe(
        (account:Account) => {
          this.selectedAccount = account;
        });
  }

}
