import { Component, OnInit } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DataStorageService } from 'src/app/shared/data-storage.service';
import { AccountService } from '../account.service';
import { Account } from '../account.model';

@Component({
  selector: 'app-account-mng-list',
  templateUrl: './account-mng-list.component.html',
  styleUrls: ['./account-mng-list.component.css']
})
export class AccountMngListComponent implements OnInit {

  accountsPending!: Account[];
  accountsFailed!: Account[];
  accountsInProgress!: Account[];

  whichTab:string='pending';
  type:string ='pending';

  constructor(private accountService:AccountService,
        private storageService: DataStorageService) { }
 
  ngOnInit(): void {

    this.onFetchData('pending');
    this.accountService.accountsChanged.subscribe(
      (accounts:Account[]) => {
        if(this.whichTab === 'pending'){
          this.accountsPending = accounts;
        } else if(this.whichTab === 'In Progress'){
          this.accountsInProgress = accounts;
        }else if(this.whichTab === 'failed'){
          this.accountsFailed = accounts;
        }
      }
    );
  }
  onFetchData(dataType: string) {
    this.storageService.fetchAccounts(dataType);
  }

  onRefreshData(){
    this.storageService.fetchAccounts(this.type);
  }


  changeTab(event:MatTabChangeEvent){
    const tab = event.tab.textLabel;
    console.log(tab);

    if(tab==="Failed")
    {
      this.whichTab = 'failed';
      this.type ='validation_failed';
      this.accountService.changeSelection(this.whichTab);
      this.onFetchData(this.type);

    }else if(tab==="In Progress"){
      this.whichTab='In Progress';
      this.type = 'validation_in_progress';
      this.accountService.changeSelection(this.whichTab);
      this.onFetchData(this.type);

    }else if(tab==="Pending"){
      this.whichTab='pending';
      this.type= 'pending';
      this.accountService.changeSelection(this.whichTab);
      this.onFetchData(this.type);
    }
  }
}
