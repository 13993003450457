<b>List of Payout to be Review </b>
<div class="row">
    
    <div class="col-md-5">
        <!--<app-payout-list (payoutWasSelected)="selectedPayout =$event"></app-payout-list> -->
        <app-payout-list></app-payout-list> 
    </div>

    <div class="col-md-7">
        <app-payout-detail *ngIf="selectedPayout; else infoText"
        [payout]="selectedPayout"></app-payout-detail>
        <ng-template #infoText>
            <p> Payout Details</p>
        </ng-template>
    </div>
</div>