import { FileObjects } from "./files.model";
import { MerchantAccountStatus } from "./merchant-account-status.model";
import { AccountCurrency } from "./account-currency.model";

export class Account {
    public id:string; 
    public created_at:number;
    public name:string;
    public legal_name:string;
    public tax_id:string;
    public phone_number:string;
    public email:string;
    public registration_id:string;
    public files: FileObjects[];
    //public status:MerchantAccountStatus;
    public type:string;
    public account_currency:AccountCurrency;

    constructor( id:string, created_at:number, name:string,
         legal_name:string, tax_id:string, phone_number:string,
         email:string, registration_id:string, files: FileObjects[],
         //status:MerchantAccountStatus, 
         type:string, account_currency:AccountCurrency){

        this.id = id; 
        this.created_at = created_at;
        this.name = name;
        this.legal_name = legal_name;
        this.tax_id = tax_id;
        this.phone_number = phone_number;
        this.email = email;
        this.registration_id = registration_id;
        this.files = files;
        this.type = type;
        this.account_currency = account_currency;
    }
}