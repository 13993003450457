import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataStorageService } from 'src/app/shared/data-storage.service';
import { AccountCurrency } from '../account-currency.model';
import { Account } from '../account.model';
import { AccountService } from '../account.service';
import { MerchantAccountStatus } from '../merchant-account-status.model';


interface AccountStatus {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-account-mng-detail',
  templateUrl: './account-mng-detail.component.html',
  styleUrls: ['./account-mng-detail.component.css']
})
export class AccountMngDetailComponent implements OnInit {

  @Input()
  account!:Account;

  selectedItem: string = "";

  accountStatus: AccountStatus[] = [
    {value: 'validation_in_progress', viewValue: 'Validation In Progress'},
    {value: 'validation_failed', viewValue: 'Validation Failed'},
    {value: 'validated', viewValue: 'Validated'}
  ];

  displayedColumns: string[] = ['name', 'kind', 'type', 'status'];

  isEnabled = false;

  constructor(private accountService: AccountService,
                private dataStorageService: DataStorageService) {}

  ngOnInit(): void {
    let merchantAccountStatus: MerchantAccountStatus;
    let account_currency: AccountCurrency;
    this.accountService.tabChanged.subscribe(
      
      (tabSelected:string) => {
        this.account = new Account('',0,'','','','','','',[],merchantAccountStatus, account_currency);
        this.selectedItem = '';
      }
    );
  }

  onSubmit(form:NgForm){
    let statusSelected :string = form.value.selectedItem;
    let merchantAccountStatus: MerchantAccountStatus;
    let account_currency: AccountCurrency;

    this.isEnabled = true;

    if((statusSelected !== undefined) || 
        (statusSelected !== '') || 
          (statusSelected !== null)){

            //this.dataStorageService.updatedAccountStatus(this.account.id, statusSelected);

            this.dataStorageService.updatedAccountStatus(this.account.account_currency.id, statusSelected).subscribe( 
              (accounts) => {
                  this.accountService.setAccounts(accounts.accounts);

                  this.account = new Account('',0,'','','','','','',[],merchantAccountStatus,account_currency);
                  this.selectedItem = '';
                  this.isEnabled = false;
              },
              (error) => {
                  console.log("This is the error ");

                  this.isEnabled = false;
              });

    } else {
      // Not 
    }

  }

  onReset(){

  }


  onSelectionChange(){
    /*
    if(this.selectedItem == "paid"){

    }else if(this.selectedItem == "failed"){

    } else if((this.selectedItem == "cancelled") || (this.selectedItem == "in_transit")) {

    }*/
  }

}
