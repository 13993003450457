import {Component} from '@angular/core';
import { Payout } from "./payout.model";
import { PayoutService } from './payout.service';

@Component({
    selector:'app-payout',
    templateUrl: './payout.component.html',
    //providers:[PayoutService]
})

export class PayoutComponent {

    selectedPayout!: Payout;
    constructor(private payoutService: PayoutService){}

    ngOnInit(){
        this.payoutService.payoutSelected.subscribe(
            (payout:Payout) => {
                this.selectedPayout = payout;
            }
        );
    }
}