//import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';

import { Payout } from '../../payout.model';
import { PayoutService } from '../../payout.service';

@Component({
  selector: 'app-payout-item',
  templateUrl: './payout-item.component.html',
  styleUrls: ['./payout-item.component.css']
})
export class PayoutItemComponent implements OnInit {

  @Input()
  payout!: Payout;

  //@Output() payoutSelected = new EventEmitter<void>();
  
  constructor(private payoutService: PayoutService) { }

  ngOnInit(): void {
  }

  onSelected(){
    //this.payoutSelected.emit();
    this.payoutService.payoutSelected.emit(this.payout);
  }

}
