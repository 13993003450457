import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { PayoutService } from '../payout/payout.service';
import { Payouts } from '../payout/payouts.model';
import { Account } from '../account-management/account.model';
import { MerchantAccountStatus } from '../account-management/merchant-account-status.model';
import { FileObjects } from '../account-management/files.model';
import { CompanyDocumentType } from '../account-management/company-document-type.model';
import { FileStatus } from '../account-management/file-status.model';
import { KindFile } from '../account-management/kind-file.model';
import { AccountService } from '../account-management/account.service';
import { Accounts } from '../account-management/accounts.model';
import { tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AccountCurrency } from '../account-management/account-currency.model';

@Injectable({ providedIn: 'root' })
export class DataStorageService {
  private file1: FileObjects = {
    name: 'file12',
    kind: KindFile.jpeg,
    type: CompanyDocumentType.national_id,
    created_by: 'Yannick P',
    created_at: 12312312,
    status: FileStatus.verification_failed,
  };

  private file2: FileObjects = {
    name: 'file32',
    kind: KindFile.pdf,
    type: CompanyDocumentType.bank_details,
    created_by: 'Tchatchoua',
    created_at: 23423423423,
    status: FileStatus.verification_in_progress,
  };

  private acctCurrency: AccountCurrency = {

    id: 'idaaaaidbbbbid',
    name: 'string',
    currency: 'string',
    balance_id_test: 'string',
    balance_id_live: '',
    is_active: 'true',
    status:MerchantAccountStatus.validated
  }

  private files: FileObjects[] = [this.file1, this.file2];
  accounts: Account[] = [
    new Account(
      '12123',
      123123123,
      'Yes Papa',
      'Yes Papa Official',
      'S1212D3',
      '1231231',
      'email@getMaxListeners.com',
      'regis_1234_id',
      this.files,
      //MerchantAccountStatus.pending,
      'business',
      this.acctCurrency
    ),

    new Account(
      '345345',
      5668668,
      'Yes Mama',
      'Yes Mama Official',
      'D32130',
      '3412312',
      'email12@getMaxListeners.com',
      'regis_1234_id',
      this.files,
      //MerchantAccountStatus.validation_failed,
      'business',
      this.acctCurrency
    ),
  ];

  constructor(
    private http: HttpClient,
    private payoutService: PayoutService,
    private accountService: AccountService,
    private httpBackend: HttpBackend
  ) {}

  fetchPayouts(status: string) {
    this.http
      .get<Payouts>(
        `${environment.baseUrl}/payouts?page=0&size=6&status=`.concat(
          status
        )
      )
      .subscribe((payouts) => {
        //this.http.get<Payouts>('https://admin.api.leapa.co/v1/payouts?page=0&size=6&status='.concat(status)).subscribe( payouts => {

        //let obj = JSON.parse(payouts[]);
        //console.log(payouts.payouts);
        this.payoutService.setPayouts(payouts.payouts);
      });
  }

  updatedPayoutStatus(
    payoutId: string,
    statusSelected: string,
    processing_id: string,
    errorMessage: string,
    errorCode: string
  ) {
    let putData = {
      status: statusSelected,
      processing_id: processing_id,
      failure_message: errorMessage,
      failure_code: errorCode,
    };

    this.http
      .put(`${environment.baseUrl}/payouts/`.concat(payoutId), putData)
      .subscribe((payout) => {
        //this.http.put('https://admin.api.leapa.co/v1/payouts/'.concat(payoutId), putData).subscribe( payout => {
        // Display successful message.
        // Enable the same button.
      });
  }

  fetchAccounts(status: string) {
    //console.log("Statussss");
    //this.accountService.setAccounts(this.accounts);

    this.http
      .get<Accounts>(
        `${environment.baseUrl}/accounts?page=0&size=6&status=`.concat(status)
      )
      .subscribe((accounts) => {
        //this.http.get<Accounts>('https://admin.api.leapa.co/v1/accounts?page=0&size=6&status='.concat(status)).subscribe( accounts => {

        //console.log(status);
        this.accountService.setAccounts(accounts.accounts);
      });
  }

  updatedAccountStatus(id: string, statusSelected: string) {
    const putData = { status: statusSelected };

    // return this.http.put<Accounts>(
    //   'http://localhost:8082/v1/accounts/'.concat(id),
    //   putData
    // );
    return this.http.put<Accounts>(
      `${environment.baseUrl}/accounts/`.concat(id),
      putData
    );
  }

  loginUser(data: any): Observable<any> {
    const http = new HttpClient(this.httpBackend);
    return http
      .post<any>(`${environment.baseAuthUrl}/auth/admin/login`, data)
      .pipe(
        tap((value) => {
          localStorage.setItem('user', JSON.stringify(value));
        })
      );
  }

  logOutUser(): Observable<any> {
    return this.http
      .post<any>(`${environment.baseAuthUrl}/auth/logout`, {})
      .pipe(
        tap(() => {
          localStorage.clear();
        })
      );
  }
}
