<div
  class="login-container"
  fxLayout="column wrap"
  fxLayoutAlign="center center"
>
  <div
    class="form-container"
    [formGroup]="loginForm"
    fxLayout="column wrap"
    fxLayoutAlign="space-between center"
  >
    <img src="/assets/svgs/leapa.svg" alt="logo" />
    <h2>Sign in to your account</h2>
    <div
      class="inputs"
      fxLayout="column wrap"
      fxLayoutAlign="space-between center"
      (keyup.enter)="signIn()"
    >
      <input
        matInput
        formControlName="email"
        placeholder="Email address"
        type="email"
        [ngClass]="{
          'is-invalid':
            loginForm.get('email').dirty &&
            loginForm.get('email').errors &&
            validationMessage
        }"
        type="text"
      />
      <span
        *ngIf="
          loginForm.get('email').errors?.required &&
          loginForm.get('email').dirty &&
          validationMessage
        "
        id="error-message"
      >
        This field is required
      </span>
      <span
        *ngIf="
          loginForm.get('email').errors?.pattern &&
          loginForm.get('email').dirty &&
          validationMessage
        "
        id="error-message"
      >
        Enter a valid email address
      </span>
      <br />
      <div
        class="pass"
        fxLayoutAlign="space-between center"
        [ngClass]="{
          'is-invalid':
            loginForm.get('password').dirty &&
            loginForm.get('password').errors &&
            validationMessage
        }"
      >
        <input
          formControlName="password"
          matInput
          [type]="hide ? 'password' : 'text'"
          id="pwd"
          placeholder="Password"
        />
        <mat-icon (click)="hide = !hide">
          {{ hide ? "visibility" : "visibility_off" }}
        </mat-icon>
      </div>
      <span
        *ngIf="
          loginForm.get('password').errors?.required &&
          loginForm.get('password').dirty &&
          validationMessage
        "
        id="error-message"
      >
        This field is required
      </span>
    </div>
    <div class="remember-me" fxLayoutAlign="space-between center">
      <mat-checkbox [disableRipple]="true" [checked]="checked">
        Remember me
      </mat-checkbox>
    </div>
    <button
      #button
      mat-flat-button
      [disabled]="!loginForm.valid"
      (click)="signIn()"
    >
      {{ btnText }}
    </button>
  </div>
  <div class="footer" fxLayoutAlign="center center">
    <div class="footer-content" fxLayoutAlign="space-between center">
      <span> <strong>leapa</strong> © {{ year }}</span>
      <a href="https://leapa.co/terms" target="_blank">Privacy & Terms</a>
    </div>
  </div>
</div>
