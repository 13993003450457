<b>List of Account to be Review </b>
<div class="row">
    
    <div class="col-md-6">
        <app-account-mng-list></app-account-mng-list> 
    </div>

    <div class="col-md-6">
        <app-account-mng-detail *ngIf="selectedAccount; else infoText"
        [account]="selectedAccount"></app-account-mng-detail>
        <ng-template #infoText>
            <p> <b>Account Details</b></p>
        </ng-template>
    </div>
</div>