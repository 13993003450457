<!--
<div class="row">

    <div class="col-xs-12">
        <button mat-raised-button color="accent" >Pending Payout</button> ||
        <button mat-raised-button color="warn">In Transit Payout</button>
    </div>
</div>
<hr>-->

<button mat-icon-button color="primary" (click)="onRefreshData()" aria-label="Example icon button with a home icon">
    <mat-icon>refresh</mat-icon>
</button>

<hr> 

<div>
    <mat-tab-group (selectedTabChange)="changeTab($event)">
        <mat-tab label="Pending"> 
            <div class="row">

                <div class="col-xs-12">
                    <app-payout-item *ngFor="let payoutEl of payoutsPending"
                    [payout]="payoutEl"></app-payout-item>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="In Transit"> 
            <div class="row">

                <div class="col-xs-12">
                    <app-payout-item *ngFor="let payoutEl of payoutsInTransit"
                    [payout]="payoutEl"></app-payout-item>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
  </div>


