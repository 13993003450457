import { Component, Input, OnInit } from '@angular/core';
import { AccountService } from '../../account.service';
import { Account } from '../../account.model';

@Component({
  selector: 'app-account-mng-item',
  templateUrl: './account-mng-item.component.html',
  styleUrls: ['./account-mng-item.component.css']
})
export class AccountMngItemComponent implements OnInit {

  @Input()
  account!: Account;

  constructor(private accountService: AccountService) { }

  ngOnInit(): void {
  }

  onSelected() {
    this.accountService.accountSelected.emit(this.account);
  }

}
