<nav class="navbar navbar-default">
  <div class="container-fluid">
    <div class="navbar-header">
      <a class="navbar-brand">Leapa Admin </a>
    </div>

    <div class="collapse navbar-collapse">
      <ul class="nav navbar-nav">
        <li>
          <a (click)="onSelect('account')">Account Management</a>
        </li>
        <li><a (click)="onSelect('payout')">Payouts</a></li>
        <li><a (click)="onSelect('manage-auth')"> Auth</a></li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <li class="dropdown" appDropDown>
          <a class="dropdown-toggle" role="button">
            Settings<a class="caret"> </a
          ></a>
          <ul class="dropdown-menu">
            <li (click)="logOut()"><a style="cursor: pointer">Logout</a></li>
            <!--<li><a style="cursor:pointer" (click)="onFetchData()">Retrieve Payout</a></li>-->
          </ul>
        </li>
      </ul>
    </div>
  </div>
</nav>
