import { EventEmitter } from "@angular/core";
import { Payout } from "./payout.model";

export class PayoutService {

    payoutSelected = new EventEmitter<Payout>();
    payoutsChanged = new EventEmitter<Payout[]>();
    tabChanged = new EventEmitter<string>();

    /*
    private payouts: Payout [] = [
        new Payout(1231212, 123123, 12323123, 'BIF','Description 4 you','Failure Code',
            'Failure Message', 'id1234444', true, true, 123231231, 'asdas12312asa', 'processing_id', 'reversal', 'status-paid', 'type'
            ),
        new Payout(332323, 54545, 8767766, 'BIF','Description 4 me','Failurinr Code',
            'Failure Message', 'idwer2342', true, true, 78878989, 'asdas12312asa', 'processing_id', 'reversal', 'status-in-process', 'type'
            )
      ];*/

    private payouts: Payout [] = [];
    getRecipes(){
        return this.payouts.slice();
    }

    setPayouts(payouts: Payout[]) {
        this.payouts = payouts;
        this.payoutsChanged.next(this.payouts);//.slice());
    }

    changeSelection(tab:string){
        this.tabChanged.next(tab);
    }

    


}