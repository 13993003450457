<button mat-icon-button color="primary" (click)="onRefreshData()" aria-label="Example icon button with a home icon">
    <mat-icon>refresh</mat-icon>
</button>

<hr> 

<div>
    <mat-tab-group (selectedTabChange)="changeTab($event)">
        <mat-tab label="Pending"> 
            <div class="row">

                <div class="col-xs-12">
                    <app-account-mng-item *ngFor="let accountEl of accountsPending"
                    [account]="accountEl"></app-account-mng-item>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="In Progress"> 
            <div class="row">

                <div class="col-xs-12">
                    <app-account-mng-item *ngFor="let accountEl of accountsInProgress"
                    [account]="accountEl"></app-account-mng-item>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Failed"> 
            <div class="row">

                <div class="col-xs-12">
                    <app-account-mng-item *ngFor="let accountEl of accountsFailed"
                    [account]="accountEl"></app-account-mng-item>
                </div>
            </div>
        </mat-tab>
    </mat-tab-group>
  </div>
