import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatButton } from '@angular/material/button';
import { SnackBarService } from 'src/app/shared/services/snack-bar.service';
import { DataStorageService } from '../shared/data-storage.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  hide = true;
  checked = true; // keep me signed in checkbox
  loginForm: FormGroup;
  validationMessage = '';
  private inputErrors = {
    required: 'This field is required',
    pattern: 'Enter a valid email',
  };
  @ViewChild('button', { static: false }) btn: MatButton;
  btnText = 'Sign In';
  year = new Date().getFullYear();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private snackBar: SnackBarService,
    private dataService: DataStorageService
  ) {
    const re = /\S+@\S+\.\S+/;
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(re)]],
      password: ['', [Validators.required]],
    });
    this.loginForm.valueChanges
      .pipe(debounceTime(2000))
      .subscribe(() => this.setMessage(this.loginForm));
  }

  ngOnInit(): void {}

  signIn(): void {
    if (this.loginForm.valid) {
      this.hideButton();
      this.dataService.loginUser(this.loginForm.value).subscribe(
        (resp) => {
          this.router.navigate(['/home']);
        },
        (err) => {
          this.snackBar.openSnackBar(err.error.message);
          this.showButton();
        }
      );
    }
  }

  showButton(): void {
    this.btnText = 'Sign In';
    this.btn.disabled = false;
  }

  hideButton(): void {
    this.btnText = 'Signing in...';
    this.btn.disabled = true;
  }

  setMessage(c: FormGroup) {
    this.validationMessage = '';
    Object.keys(c.controls).map((key) => {
      const control = c.get(key);
      if (control && (control.touched || control.dirty) && control.errors) {
        this.validationMessage = Object.keys(control.errors)
          .map((i) => (this.validationMessage += this.inputErrors[i]))
          .join(' ');
      }
    });
  }
}
