import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { PayoutComponent } from './payout/payout.component';
import { ManageAuthLeapaComponent } from './manage-auth-leapa/manage-auth-leapa.component';
import { PayoutListComponent } from './payout/payout-list/payout-list.component';
import { PayoutItemComponent } from './payout/payout-list/payout-item/payout-item.component';
import { PayoutDetailComponent } from './payout/payout-detail/payout-detail.component';
import { HeaderComponent } from './header/header.component';
import { DropdownDirective } from './shared/dropdown.directive';
import { PayoutService } from './payout/payout.service';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountManagementComponent } from './account-management/account-management.component';
import { AccountMngListComponent } from './account-management/account-mng-list/account-mng-list.component';
import { AccountMngDetailComponent } from './account-management/account-mng-detail/account-mng-detail.component';
import { AccountMngItemComponent } from './account-management/account-mng-list/account-mng-item/account-mng-item.component';
import { AccountService } from './account-management/account.service';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { httpInterceptorProviders } from './shared/interceptor';

@NgModule({
  declarations: [
    AppComponent,
    PayoutComponent,
    ManageAuthLeapaComponent,
    PayoutListComponent,
    PayoutItemComponent,
    PayoutDetailComponent,
    HeaderComponent,
    DropdownDirective,
    AccountManagementComponent,
    AccountMngListComponent,
    AccountMngDetailComponent,
    AccountMngItemComponent,
    HomeComponent,
    LoginComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CommonModule,
  ],
  providers: [PayoutService, AccountService, httpInterceptorProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
