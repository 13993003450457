<app-header (featureSelected)="onNavigate($event)"></app-header>

<div class="container">
  <div class="row">
    <div class="col-md-12">
      <app-payout *ngIf="loadedFeature === 'payout'"></app-payout>
    </div>

    <div class="col-md-12">
      <app-manage-auth-leapa
        *ngIf="loadedFeature === 'manage-auth'"
      ></app-manage-auth-leapa>
    </div>

    <div class="col-md-12">
      <app-account-management
        *ngIf="loadedFeature === 'account'"
      ></app-account-management>
    </div>
  </div>
</div>
