import { Component, OnInit} from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs/tab-group';
import { DataStorageService } from 'src/app/shared/data-storage.service';

import { Payout } from '../payout.model';
import { PayoutService } from '../payout.service';

@Component({
  selector: 'app-payout-list',
  templateUrl: './payout-list.component.html',
  styleUrls: ['./payout-list.component.css']
})
export class PayoutListComponent implements OnInit {

  //@Output() payoutWasSelected =  new EventEmitter<Payout>();

  payoutsPending!: Payout[];
  payoutsInTransit!: Payout[];
  whichTab:string='pending';

  constructor(private payoutService: PayoutService, private storageService:DataStorageService) { 
    
  }

  ngOnInit(): void {
    //this.payoutsPending= this.fetchData('pending');//this.payoutService.getRecipes();
    this.onFetchData('pending');
    this.payoutService.payoutsChanged.subscribe(
      (payouts:Payout[]) => {

        if(this.whichTab === 'pending'){
          this.payoutsPending = payouts;
        } else if(this.whichTab === 'in_transit'){
          this.payoutsInTransit = payouts;
        }
      }
    );
  }

  onFetchData(dataType:string){
    this.storageService.fetchPayouts(dataType);
  }

  onRefreshData(){
    this.storageService.fetchPayouts(this.whichTab);
  }
  /*
  onPayoutSelected(payout:Payout){
    this.payoutWasSelected.emit(payout);
  }*/

  /*
  setPayouts(payouts:Payout[]){
    this.payouts = payouts;
  } */

  onLoadPayout(payoutType:string) {
    //
  }

  changeTab(event:MatTabChangeEvent){
    const tab = event.tab.textLabel;
    console.log(tab);
    if(tab==="In Transit")
    {
      this.whichTab = 'in_transit';
      this.payoutService.changeSelection(this.whichTab);
      this.onFetchData('in_transit');
    } else if(tab==="Pending"){
      this.whichTab='pending';
      this.payoutService.changeSelection(this.whichTab);
      this.onFetchData('pending');
    }
  }
}
