import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DataStorageService } from '../shared/data-storage.service';
import { SnackBarService } from '../shared/services/snack-bar.service';
//import * as EventEmitter from 'node:events';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  @Output() featureSelected = new EventEmitter<string>();
  constructor(
    private storageService: DataStorageService,
    private snackBar: SnackBarService,
    private router: Router
  ) {}

  ngOnInit(): void {}

  onSelect(feature: string) {
    this.featureSelected.emit(feature);
  }

  logOut(): void {
    this.storageService.logOutUser().subscribe(
      () => {
        this.router.navigate(['/login']);
      },
      (err) => {
        this.snackBar.openSnackBar(err);
      }
    );
  }

  /*
  onFetchData(){
    this.storageService.fetchPayouts();
  }*/
}
