import { EventEmitter } from "@angular/core";
import { Account } from "./account.model";
import { CompanyDocumentType } from "./company-document-type.model";
import { FileStatus } from "./file-status.model";
import { FileObjects } from "./files.model";
import { KindFile } from "./kind-file.model";
import { MerchantAccountStatus } from "./merchant-account-status.model";

export class AccountService {

    accountSelected = new EventEmitter<Account>();
    accountsChanged = new EventEmitter<Account[]>();
    tabChanged =  new EventEmitter<string>();

    private accounts: Account [] =[];

    /*
    getAccounts(){
        return this.accounts.slice();
    }*/

    setAccounts(accounts:Account[]){
        this.accounts = accounts;
        this.accountsChanged.next(this.accounts);
    }

    changeSelection(tab:string){
        this.tabChanged.next(tab);
    }

}