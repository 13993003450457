export class Payout {

    public amount: number;
    public  arrival_date: number;
    public  created: number;
    public  currency: string;
    public  description: string ;
    public  failure_code: string; //Enum
    public  failure_message: string;
    public  id: string ;
    public  is_live: boolean;
    public  liveMode: boolean;
    public  modified: number;
    public payout_id: string;
    public  processing_id: string;
    public  reversal: string;
    public  status: string; // Enum
    public  type: string; //Enum

    constructor(  amount: number, arrival_date: number,
        created: number,
        currency: string,
        description: string,
        failure_code: string, //Enum
        failure_message: string,
        id: string,
        is_live: boolean,
        liveMode: boolean,
         modified: number,
        payout_id: string,
        processing_id: string,
        reversal: string,
        status: string, // Enum
        type: string //Enum
        ){
            this.amount=amount;
            this.arrival_date = arrival_date;
            this.created = created;
            this.currency = currency;
            this.description = description;
            this.failure_code = failure_code;
            this.failure_message = failure_message;
            this.id = id;
            this.is_live = is_live;
            this.liveMode = liveMode;
            this.modified = modified;
            this.payout_id = payout_id;
            this.processing_id = processing_id;
            this.reversal = reversal;
            this.status = status;
            this.type = type;
            
        }


}