<div > 
    <div class="row">
        <div class="col-xs-6">
            <span><b>Merchant ID:</b> {{account.id}}</span>
        </div>
        <div class="col-xs-6">
            <span><b>Business Name:</b> {{account.name}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-6">
            <span><b>Business Type:</b> {{account.type}}</span>
        </div>
        <div class="col-xs-6">
            <span><b>Currency:</b> </span> 
            <span *ngIf="account.account_currency !== undefined"> {{account.account_currency.currency}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col-xs-6">
            <span><b>Registration Number:</b> {{account.registration_id}}</span>
        </div>
        <div class="col-xs-6">
            <span> <b>Tax ID:</b> {{account.tax_id}}</span>
        </div>
    </div>
    <br/>
    <div class="row">
        <div class="col-xs-12">
            <span><b>Current Account Status:</b> </span>
        </div>
        <div class="col-xs-6">
            <span><b>Currency:</b> </span> 
            <span *ngIf="account.account_currency !== undefined"> {{account.account_currency.id}}</span>
        </div>

    </div>
    <br/>
    <div class="row">
        <div class="col-xs-12">
            <span><b>List Of Documents Uploaded:</b></span>
            <table mat-table [dataSource]="account.files" class="mat-elevation-z8">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name}} </td>
                  </ng-container>
                
                  <!-- Name Column -->
                  <ng-container matColumnDef="kind">
                    <th mat-header-cell *matHeaderCellDef> Kind </th>
                    <td mat-cell *matCellDef="let element"> {{element.kind}} </td>
                  </ng-container>
                
                  <!-- Weight Column -->
                  <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef> Type </th>
                    <td mat-cell *matCellDef="let element"> {{element.type}} </td>
                  </ng-container>
                
                  <!-- Symbol Column -->
                  <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef> Status </th>
                    <td mat-cell *matCellDef="let element"> {{element.status}} </td>
                  </ng-container>
                
                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

        </div>
    </div>
    <br/>
    <div class="row">
        <div class="col-xs-12">
            <form #f="ngForm" (ngSubmit)="onSubmit(f)">
            
                <mat-form-field appearance="fill">
                    <mat-label>Change Status</mat-label>
                    <mat-select  name="selectedItem" [(ngModel)]="selectedItem" (ngModelChange)="onSelectionChange()" >
                        <mat-option *ngFor="let status of accountStatus"   [value]="status.value">
                        {{status.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <br>
                <button type="submit" mat-raised-button color="primary" [disabled]="isEnabled">save</button>

            </form>
        </div>
    </div>
</div>
