<div *ngIf="showDetails">
  <div class="row">
    <div class="col-xs-12">
      <span><b>ID:</b> {{ payout.id }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <span> <b>Payout ID:</b> <br />{{ payout.payout_id }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <span> <b>Status:</b> {{ payout.status }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <span> <b>Description:</b> <br />{{ payout.description }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <span> <b>Amount:</b> <br />{{ payout.amount }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <span>
        <b>Arrival Date:</b> <br />{{
          payout.arrival_date | date: "mediumDate"
        }}
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <span>
        <b>Date Created:</b> <br />{{ payout.created | date: "mediumDate" }}
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <span> <b>Currency:</b> <br />{{ payout.currency | uppercase }}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-xs-12">
      <span>
        <b>Date Modified:</b> <br />{{
          payout.modified | date: "mediumDate"
        }}</span
      >
    </div>
  </div>
  <br />
  <div class="row">
    <div class="col-xs-12">
      <form #f="ngForm" (ngSubmit)="onSubmit(f)">
        <mat-form-field appearance="fill">
          <mat-label>Select Status</mat-label>
          <mat-select
            name="selectedItem"
            [(ngModel)]="selectedItem"
            (ngModelChange)="onSelectionChange()"
          >
            <mat-option
              *ngFor="let status of payoutStatus"
              [value]="status.value"
            >
              {{ status.viewValue }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        |
        <mat-form-field appearance="fill" *ngIf="showProcessingId">
          <mat-label>Processing Id</mat-label>
          <input
            matInput
            placeholder="Id"
            ngModel
            value=""
            name="processingId"
          />
        </mat-form-field>
        |
        <mat-form-field appearance="fill" *ngIf="showErrorMessage">
          <mat-label>Error Message</mat-label>
          <input
            matInput
            placeholder="error"
            ngModel
            value=""
            name="errorMessage"
          />
        </mat-form-field>
        |
        <mat-form-field appearance="fill" *ngIf="showErrorCode">
          <mat-label>Error Code</mat-label>
          <input
            matInput
            placeholder="Code"
            ngModel
            value=""
            name="errorCode"
          />
        </mat-form-field>
        |
        <br />
        <button type="submit" mat-raised-button color="primary">save</button>
      </form>
      <!--
            <div class="btn-group" appDropDown>
                <button type="button" class="btn btn-primary dropdown-toggle">Change Status<span class="caret"></span></button>
                <ul class="dropdown-menu">
                    <li><a (click)="onChangeState('inTransit')" style="cursor:pointer;">InTransit</a></li>
                    <li><a (click)="onChangeState('paid')" style="cursor:pointer;">Paid</a></li>
                    <li><a (click)="onChangeState('cancel')" style="cursor:pointer;">Cancel</a></li>
                    <li><a (click)="onChangeState('failed')" style="cursor:pointer;">Failed</a></li>
                </ul>
            </div> -->
    </div>
  </div>
</div>
