import { Component, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataStorageService } from 'src/app/shared/data-storage.service';

import { Payout } from '../payout.model';
import { PayoutService } from '../payout.service';


interface Status {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-payout-detail',
  templateUrl: './payout-detail.component.html',
  styleUrls: ['./payout-detail.component.css']
})
export class PayoutDetailComponent implements OnInit {

  @Input()
  payout!: Payout;

  payoutStatus: Status[] = [
    {value: 'in_transit', viewValue: 'In Transit'},
    {value: 'paid', viewValue: 'Paid'},
    {value: 'canceled', viewValue: 'Canceled'},
    {value: 'failed', viewValue: 'Failed'}
  ];

  showProcessingId:boolean=false;
  showErrorMessage:boolean=false;
  showErrorCode:boolean=false;
  showDetails:boolean = false;

  selectedItem: string="";

  constructor(private payoutService:PayoutService, private dataStorageService:DataStorageService) { }

  ngOnInit(): void {
    this.showDetails = true;
    this.payoutService.tabChanged.subscribe(
      (tabSelected:string) => {
        console.log("Tab had changed");
        this.payout = new Payout(0, 0, 0, '','','','', '', false, false, 0, '', '', '', '', '');
        this.selectedItem = '';
        this.showProcessingId = false;
        this.showErrorMessage = false;
        this.showErrorCode = false;
        this.showDetails = false;
      }
    );

    this.payoutService.payoutSelected.subscribe(
      (payout:Payout) => {
        this.showDetails = true;

        this.selectedItem = '';
        this.showProcessingId = false;
        this.showErrorMessage = false;
        this.showErrorCode = false;

      });
  }

 
  onSubmit(form:NgForm){
    console.log(form);
    let statusSelected :string = form.value.selectedItem;


    /*
    payoutStatus: updateForm[] = [
      {value: 'in_transit', viewValue: 'In Transit'},
      {value: 'paid', viewValue: 'Paid'},
      {value: 'canceled', viewValue: 'Cancelled'},
      {value: 'failed', viewValue: 'Failed'}
    ];*/

    if((statusSelected !== undefined ) || 
        (statusSelected !== '') || 
          (statusSelected !== null)){

            if (statusSelected === 'in_transit'){

              this.dataStorageService.updatedPayoutStatus(this.payout.id, statusSelected, null, null, null);
      
            }else if (statusSelected === 'canceled'){
              console.log('Canceled');
              this.dataStorageService.updatedPayoutStatus(this.payout.id, statusSelected, null, null, null);
            } else if (statusSelected === 'failed'){
              this.dataStorageService.updatedPayoutStatus(this.payout.id, statusSelected, null, form.value.errorMessage, form.value.errorCode);
            } else if (statusSelected === 'paid'){
              this.dataStorageService.updatedPayoutStatus(this.payout.id, statusSelected, form.value.processingId, null, null);
            } else {
              //No save
            }
    } else {
      //No Saving

    }
  }

  
  onSelectionChange(){
    if(this.selectedItem == "paid"){
      this.showProcessingId = true;
      this.showErrorMessage = false;
      this.showErrorCode = false;
    }else if(this.selectedItem == "failed"){
      this.showErrorMessage = true;
      this.showErrorCode = true;
      this.showProcessingId =false;
    } else if((this.selectedItem == "cancelled") || (this.selectedItem == "in_transit")) {
      this.showProcessingId =false;
      this.showErrorMessage =false;
      this.showErrorCode =false;
    }
  }

}
